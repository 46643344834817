import {NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {
  AuthenticationService,
  CommonsModule,
  RestService
} from 'commons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ForwardComponent } from './components/forward/forward.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForwardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    CommonsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en'},
    RestService,
    AuthenticationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
